import { Link } from 'react-router-dom'

// styles
import './SchoolsList.css'

export default function SchoolsList({ data }) {

  if (data.length === 0) {
    return <div className="error">没有适合您的学校</div>
  }

  return (
    <div className="schools-list-wrap">
      <h4>共为您找到{data.length}个学校，如下是其中的2个，更多结果请添加我们的微信:</h4>
      <img src="http://13.211.41.165/wp-content/uploads/2021/09/qr-code-1a.png" width="120px" height="120px"/>
      <div className="schools-list">
        {data.slice(0, 2).map(school => (
          <div key={school.id} className="card">
            <h3>{school.schoolName}</h3>
          </div>
        ))}
      </div>

      <h3>游洋课程介绍</h3>
      <p>http://youyang.com.au/aeas-course/</p>
    </div>
  )
}
