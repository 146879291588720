import { useState, useEffect } from 'react'
import { useFetch } from '../../hooks/useFetch'
import SchoolsListShort from '../../components/SchoolsListShort'
import './Students.css'

export default function Students() {
  const [name, setName] = useState('')
  const [gender, setGender] = useState('')
  const [schoolGender, setSchoolGender] = useState([])

  const { data:schools, isPending:schoolPending, error:schoolError } = useFetch('http://localhost:3000/schools')
  const { data:students, isPending, error } = useFetch('http://localhost:3600/students')

  const Search = (schools) => {
    return schools.filter(
      (item) =>
        (schoolGender.length === 0 || schoolGender.some((goal) => item.schoolGender.includes(goal)))
    )
  }
  
  const schoolGenderOptions = [{ value: 'girl', label: '女校' },{ value: 'boy', label: '男校' },{ value: 'co', label: '混校' },]
  const handleSchoolGender= (optionValue) => {
    if (schoolGender.includes(optionValue)) {
      setSchoolGender(schoolGender.filter((o) => o !== optionValue))
    } else {
      setSchoolGender([...schoolGender, optionValue])
    }
  }

  return (
    <div >
      <div id="all-input">
        <h3 className="page-title">学生信息</h3>
        <div>
          <div className="schools-list">
            {error && <p className="error">{error}</p>}
            {isPending && <p className="loading">Loading...</p>}

            {students && students.slice(0, 5).map(student => (
             
                <div key={student.id} className="single-stu">
                  <h3> {student.name}</h3>
                  <p> 学生年龄：{student.age}</p>
                  <p> 学校性质：{student.schoolGender && 
                  student.schoolGender.map(item=>(<span key={item}>{item}</span>))} 
                  </p>
                </div>
            
            ))}
          </div>

          
        </div>
      </div>
    </div>
  )
}
